import * as React from "react";
import styled from "styled-components";
import Menu from "react-burger-menu/lib/menus/slide";
import { Link } from "react-router-dom";

import MenuP from "../images/nav/menu.png";
import Res from "../images/nav/res.png";
import Vouch from "../images/nav/vouch.png";
import Hours from "../images/nav/hours.png";

var styles = {
  bmBurgerButton: {
    position: "relative",
    width: "36px",
    height: "30px",
    zIndex: "2",
    // right: "48vw",
    // top: "26px",
  },
  bmBurgerBars: {
    background: "#f8be98",
  },
  bmBurgerBarsHover: {
    background: "#a90000",
  },
  bmCrossButton: {
    height: "24px",
    width: "24px",
  },
  bmCross: {
    background: "#bdc3c7",
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%",
    maxWidth: "90%",
    left: "0",
    top: "0",
  },
  bmMenu: {
    background: "#465941",
    padding: "2.5em 1.5em 0",
    fontSize: "1.15em",
    height: "100%",
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItemList: {
    color: "#b8b7ad",
    padding: "0.8em",
    height: "95%",
  },
  bmItem: {
    display: "block",
  },
  bmOverlay: {
    background: "#465941",
    top: "0",
    left: "0",
  },
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
`;

const Item = styled.img`
  height: 40px;
  display: flex;
  padding: 15px 0;
`;

class Burger extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    };
  }

  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }

  // This can be used to close the menu, e.g. when a user clicks a menu item
  closeMenu() {
    this.setState({ menuOpen: false });
  }

  // This can be used to toggle the menu, e.g. when using a custom icon
  // Tip: You probably want to hide either/both default icons if using a custom icon
  // See https://github.com/negomi/react-burger-menu#custom-icons
  toggleMenu() {
    this.setState((state) => ({ menuOpen: !state.menuOpen }));
  }

  render() {
    return (
      <Container>
        <Menu
          styles={styles}
          isOpen={this.state.menuOpen}
          onStateChange={(state) => this.handleStateChange(state)}
        >
          <Link to="/menu" >
            <Item src={MenuP} alt="Menu" />
          </Link>
          <a
            href="https://bookings.nowbookit.com/?accountid=1e572e62-f311-4830-a8b0-d19dd0a287a6&venueid=4289&theme=outlined-light&colors=hex,212121&font=Inconsolata"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Item src={Res} alt="Reservations" />
          </a>
          <a
            href="https://squareup.com/gift/ML9390RCYVN4E/order"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Item src={Vouch} alt="Vouchers" />
          </a>
          <Link to="/hours" >
            <Item src={Hours} alt="Menu" />
          </Link>
        </Menu>
      </Container>
    );
  }
}

export default Burger;
