import React from "react";
import styled from "styled-components";
import Logo from "../images/logo.png";
import { Link } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  position: relative;

  width: 100%;
  height: 200px;

  z-index: 5;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Image = styled.img`
  height: 200px;
  display: flex;
`;

const Header = () => {
  return (
    <Container>
      <Link to="/">
        <Image src={Logo} alt="Bar Que Sera Logo" />
      </Link>
    </Container>
  );
};

export default Header;
