import React from "react";
import styled from "styled-components";

import Header from "../components/header";
import Nav from "../components/nav";
import Burger from "../components/burger";
import Socials from "../components/socials";
import Footer from "../components/footer";
import ImageBar from "../components/imageBar";
import Modal from "../components/modal";

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  width: 100%;
  overflow: hidden;
  min-height: 100vh;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Blurb = styled.div`
  text-align: center;
  width: 90vw;
  font-size: 1.3em;
  padding: 70px 0;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
    width: 90vw;
  }
  @media only screen and (min-width: 1024px) {
    width: 80vw;
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Promo = styled.div`
  margin: 15px 0;
  font-size: 0.8em;
`;

const Text = styled.div`
  margin-bottom: 50px;
  font-size: 1em;
  width: 90%;
  text-align: center;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const FootLink = styled.a`
  text-decoration: none;
  color: #f8be98;
`;

var pageWidth = window.screen.availWidth;
var pageHeight = window.screen.availHeight;

const Landing = () => {
  // console.log(pageWidth);
  // console.log(pageHeight);
  return (
    <Container>
      <Header />
      {pageWidth > 768 ? (<Nav />):(<Burger />)}
      {/* <Modal/> */}
      <Blurb>
        Bar Que Sera is a relaxed bar & restaurant on First Ave, Sawtell.
        <br />
        <br />
        Owners Bianca and Ric returned to their Coffs Coast roots to ride out
        the pandemic in 2020 and from there, a series of “que sera” moments
        culminated in the opening of their first venue in Dec 2020. Shiny green
        subway tiles, timber flooring and architectural lighting set the scene
        for drinks, dining and everything in between. Drop-in for a beer and bar
        snacks, graze over share plates with a cocktail in hand or settle in
        with seasonal large plates and a wine list that celebrates quality
        independent producers.
      </Blurb>
      <Footer />
      <Socials />
      <Text>Check out our rowdy neighbor {" "}<FootLink
          href="https://www.mortys-joint.com//"
          target="_blank"
          rel="noopener noreferrer"
        >
          Morty's Joint (mortys-joint.com)
        </FootLink>{" "}for all your fried chicken & burger needs.</Text>
      <ImageBar />
      <Promo>Created and hosted by HolisTechAus &copy;2022</Promo>
    </Container>
  );
};

export default Landing;
