import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: block;

  margin-left: auto;
  margin-right: auto;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const FootSection = styled.div`
  margin: 15px 0;
  text-align: center;
`;

const FootLink = styled.a`
  text-decoration: none;
  color: #f8be98;
`;

const Footer = () => {
  return (
    <Container>
      <FootSection>
        <FootLink
          href="https://g.page/bar-que-sera-sawtell?share"
          target="_blank"
          rel="noopener noreferrer"
        >
          61 First Ave, Sawtell, NSW
        </FootLink>
      </FootSection>
      <FootSection>
        <FootLink href="mailto: hello@bar-que-sera.com">
          hello@bar-que-sera.com
        </FootLink>{" // "}
        <FootLink id="phone" href="tel:+61439375894">
          0439 375 894
        </FootLink>
      </FootSection>
    </Container>
  );
};

export default Footer;
