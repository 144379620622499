import React from "react";
import styled from "styled-components";

import facebook from "../images/fb.png";
import insta from "../images/insta.png";

const Container = styled.div`
  display: block;

  margin-left: auto;
  margin-right: auto;
  padding: 20px 20px;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const SocialLink = styled.a`
  display: flex;
  justify-content: center;
  padding: 0 20px;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Icon = styled.img`
  
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Socials = () => {
  return (
    <Container>
      <FlexContainer>
        <SocialLink
          href="https://www.instagram.com/bar_que_sera/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon src={insta} alt="instagram logo"></Icon>
        </SocialLink>
        <SocialLink
          href="https://www.facebook.com/barqueserasawtell"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon src={facebook} alt="facebook logo"></Icon>
        </SocialLink>
      </FlexContainer>
    </Container>
  );
};

export default Socials;
