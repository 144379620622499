import React from "react";
import styled from "styled-components";
import ProgressiveImage from "./progressiveImage";

// import Left from "../images/gallery/left.jpg";
// import Left_tiny from "../images/gallery/left_tiny.jpg";
import Mid from "../images/gallery/middle.jpg";
import Mid_tiny from "../images/gallery/middle_tiny.jpg";
// import Right from "../images/gallery/right.jpg";
// import Right_tiny from "../images/gallery/right_tiny.jpg";

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 99%;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
    width: 95%;
    flex-flow: column nowrap;
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1500px) {
    flex-flow: row nowrap;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  margin: 10px 20px;
  max-width: 1000px;

  @media only screen and (min-width: 480px) {
    width: 100%;
    height: auto;
  }
  @media only screen and (min-width: 768px) {
    width: 100%;
    height: auto;
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

// var pageWidth = window.screen.availWidth;

const ImageBar = () => {
  return (
    <Container>
      <ImageContainer>
        <ProgressiveImage src={Mid} placeholderSrc={Mid_tiny} alt="" />
      </ImageContainer>
    </Container>
  );
};

export default ImageBar;
