import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
@font-face {
    font-family: "Organda";
    font-style: normal;
    font-weight: 400;
    stretch:100;
    src: url("font/OrgandaMN.otf") format("opentype");
    src: local('?'), url("font/OrgandaMN.otf") format("opentype");
  }
`;

export default GlobalStyle;
