import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Menu from "../images/nav/menu.png";
import Res from "../images/nav/res.png";
import Vouch from "../images/nav/vouch.png";
import Hours from "../images/nav/hours.png";

const Container = styled.div`
  height: 40px;
  width: 95vw;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
    height: 40px;
    width: 60vw;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
  }
`;

const Item = styled.h1`
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 45px;
  font-family: "OrgandaMN", "Organda", Inconsolata, sans-serif;
  display: flex;  
`;

const Nav = () => {
  return (
    <Container>
      <Link to="/menu">
        <Item>Menu</Item>
      </Link>
      <a
        href="https://bookings.nowbookit.com/?accountid=1e572e62-f311-4830-a8b0-d19dd0a287a6&venueid=4289&theme=outlined-light&colors=hex,212121&font=Inconsolata"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Item>Reservations</Item>
      </a>
      <a
        href="https://squareup.com/gift/ML9390RCYVN4E/order"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Item>Vouchers</Item>
      </a>
      <Link to="/hours">
        <Item>Hours</Item>
      </Link>
    </Container>
  );
};

export default Nav;
