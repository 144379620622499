import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: block;
  margin: 15px auto;
`;

const Title = styled.h1`
  text-transform: uppercase;
  margin-bottom: 0;
  text-align: center;
  color: #f8be98;
  font-size: 60px;
  font-family: "OrgandaMN", "Organda", Inconsolata, sans-serif;
  padding-top: 10px;
`;

const Text = styled.h4`  
  text-align: center;
  color: white;
  font-size: 19px;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;
const ColorText = styled.h4`
  text-align: center;
  color: #f8be98;
  font-size: 19px;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const LongWeekend = () => {
  return (
    <Container>
        <Title>LONG WEEKEND <br/> TRADING HOURS</Title>
        <Text>
          Fri, 29th Sept - Open 5pm<br/>
          Sat, 30th Sept - Open 3pm<br/>          
          Aperitivo 3-5pm<br/>          
          Drink Specials and Bar Snacks<br/>          
          Sun, 1st Oct - Open 5pm<br/>          
          Mon, 2nd Oct - Closed<br/>          
        </Text>
        <ColorText>Bookings available, <br/> walk-ins always welcome.</ColorText>
    </Container>
  );
};

export default LongWeekend;