import React from "react";
import styled from "styled-components";
import "../App.css";

import Header from "../components/header";
import Nav from "../components/nav";
import Burger from "../components/burger";
import Socials from "../components/socials";
import Footer from "../components/footer";

// import {snacks} from "../data/snacks";
// import small from "../data/small";
// import large from "../data/large";
// import sides from "../data/sides";

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  width: 100%;
  overflow: hidden;
  min-height: 100vh;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const CentredSection = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  @media only screen and (min-width: 768px) {
  }
`;

const MenuSection = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  @media only screen and (min-width: 768px) {
  }
`;

const MenuItem = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  @media only screen and (min-width: 768px) {
    flex-flow: row nowrap;
  }
`;

const Title = styled.h1`
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 45px;
  font-family: "OrgandaMN", "Organda", Inconsolata, sans-serif;
  padding-top: 50px;
`;

const SubTitle = styled.h2`
  text-transform: uppercase;
  text-align: center;
`;

const FeedTitle = styled.h2`
  text-transform: uppercase;
  text-align: center;
`;

const ItemTitle = styled.div`
  text-transform: uppercase;
  font-size: 1.2em;
  padding: 0 5px;
  @media only screen and (min-width: 768px) {
  }
`;

const ItemDesc = styled.div`
  padding: 0 5px;
  text-align: center;
  max-width: 500px;
  @media only screen and (min-width: 768px) {
  }
`;

const FeedDesc = styled.div`
  text-align: center;
  width: 90%;
  max-width: 500px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 20px;
`;

const Disclaimer = styled.div`
  text-align: center;
  max-width: 500px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-size: 0.7em;
  padding: 10px 0;
`;

const Price = styled.div`
  float: right;
  text-align: center;
  padding: 0 5px;
  @media only screen and (min-width: 768px) {
  }
`;

var pageWidth = window.screen.availWidth;

// const snacks = [
//   { title: "WARM OLIVES", desc: "marinated ‘ALTO’ mixed olives", price: "8" },

//   { title: "PRAWN TOAST", desc: "wagyu bresaola, sesame (ea)", price: "5" },

//   {
//     title: "CROQUETTE",
//     desc: "local mushroom, parmesan, lemon mayo (ea)",
//     price: "6",
//   }
// ];
const snacks = [
  { title: "WARM OLIVES", desc: "marinated ‘ALTO’ mixed olives", price: "9" },

  { title: "PRAWN TOAST", desc: "wagyu bresaola, sesame (ea)", price: "6" },
  {
    title: "WHITE ANCHOVY",
    desc: "gordal olive, lemon mayo, onion tart (ea)",
    price: "7",
  },
  {
    title: "CROQUETTE",
    desc: "edamame, miso, spring onion, parmesan (ea)",
    price: "6",
  },

  // {
  //   title: "WILD BOAR RILLETTES",
  //   desc: "light rye, pickled onion, mustard (ea)",
  //   price: "6",
  // },
];

// const small = [
//   {
//     title: "HOUSE FOCACCIA ",
//     desc: "whipped ricotta, burnt butter",
//     price: "7",
//   },
//   {
//     title: "ANCHOVY PLATE",
//     desc: "cured egg yolk, pickled onion",
//     price: "20",
//   },
//   {
//     title: "BUFFALO MOZZARELLA",
//     desc: "pickled mushroom, forbidden rice",
//     price: "25",
//   },
//   {
//     title: "ROAST PUMPKIN",
//     desc: "miso, spiced dukkah, hummus",
//     price: "18",
//   },
// ];
const small = [
  {
    title: "SOURDOUGH FLATBREAD",
    desc: "labne, burnt butter, sumac",
    price: "8",
  },
  {
    title: "BALMAIN BUG",
    desc: "house crumpet, lemon mayo, chives",
    price: "24",
  },
  {
    title: "BUFFALO MOZZARELLA",
    desc: "marinated zucchini, puffed capers, almond, mint",
    price: "23",
  },
  {
    title: "CHARRED SQUASH",
    desc: "hot honey, walnut, mixed herbs, blue cheese labne",
    price: "18",
  },
];

// const large = [
//   {
//     title: "BEEF LASAGNE",
//     desc: "house pasta, bechamel (veg option available)",
//     price: "22",
//   }
// ];
const large = [
  {
    title: "PAN-FRIED GNOCCHI",
    desc: "House-made potato gnocchi, roast pumpkin puree, burnt butter, sage gremolata, hazelnut pangrattato",
    price: "36",
  },
  {
    title: "LAMB RUMP",
    desc: "slow-cooked and seared, house mint sauce (gf)",
    price: "42",
  },
];

// const sides = [
//   {
//     title: "MIXED LEAVES",
//     desc: "‘New Life’ greens, honey mustard dressing",
//     price: "10",
//   },
//   {
//     title: "HANDCUT CHIPS",
//     desc: "nori, sesame, lemon mayo",
//     price: "10",
//   },
// ];
const sides = [
  {
    title: "GREEN BEANS",
    desc: "sherry vinegar, confit garlic, parmesan",
    price: "12",
  },
  {
    title: "HANDCUT CHIPS",
    desc: "nori, sesame, lemon mayo",
    price: "12",
  },
];

const Index = () => {
  return (
    <Container>
      <Header />
      {pageWidth > 768 ? <Nav /> : <Burger />}
      <CentredSection>
        {/* <Title>Lasagne Party</Title> */}
        <Title>SPRING MENU</Title>
      </CentredSection>
      {/* <Disclaimer>THIS IS ONE-OFF MENU AVAILABLE FRI, 31/05 & SAT, 1/06 ONLY. OUR REGULAR MENU WILL NOT BE AVAILABLE ON THESE DATES.</Disclaimer> */}
      <Disclaimer>*THIS IS A SAMPLE MENU ONLY, DISHES MAY VARY OR CHANGE COMPLETELY DEPENDING ON PRODUCE AVAILABILITY
      *</Disclaimer>
      <SubTitle>Snacks</SubTitle>
      <MenuSection>
        {snacks.map(function (s, id) {
          return pageWidth > 768 ? (
            <MenuItem key={id}>
              <ItemTitle>{s.title}</ItemTitle>
              <ItemDesc>{s.desc}</ItemDesc>
              <Price>{s.price}</Price>
            </MenuItem>
          ) : (
            <MenuItem>
              <ItemTitle>
                {s.title} / {s.price}
              </ItemTitle>
              <ItemDesc>{s.desc}</ItemDesc>
            </MenuItem>
          );
        })}
      </MenuSection>
      <SubTitle>Small Plates</SubTitle>
      <MenuSection>
        {small.map(function (s, id) {
          return pageWidth > 768 ? (
            <MenuItem key={id}>
              <ItemTitle>{s.title}</ItemTitle>
              <ItemDesc>{s.desc}</ItemDesc>
              <Price>{s.price}</Price>
            </MenuItem>
          ) : (
            <MenuItem>
              <ItemTitle>
                {s.title} / {s.price}
              </ItemTitle>
              <ItemDesc>{s.desc}</ItemDesc>
            </MenuItem>
          );
        })}
      </MenuSection>
      <SubTitle>Large Plates</SubTitle>
      {/* <SubTitle>Large Plates</SubTitle> */}
      <MenuSection>
        {large.map(function (s, id) {
          return pageWidth > 768 ? (
            <MenuItem key={id}>
              <ItemTitle>{s.title}</ItemTitle>
              <ItemDesc>{s.desc}</ItemDesc>
              <Price>{s.price}</Price>
            </MenuItem>
          ) : (
            <MenuItem>
              <ItemTitle>
                {s.title} / {s.price}
              </ItemTitle>
              <ItemDesc>{s.desc}</ItemDesc>
            </MenuItem>
          );
        })}
      </MenuSection>
      <SubTitle>Sides</SubTitle>
      <MenuSection>
        {sides.map(function (s, id) {
          return pageWidth > 768 ? (
            <MenuItem key={id}>
              <ItemTitle>{s.title}</ItemTitle>
              <ItemDesc>{s.desc}</ItemDesc>
              <Price>{s.price}</Price>
            </MenuItem>
          ) : (
            <MenuItem>
              <ItemTitle>
                {s.title} / {s.price}
              </ItemTitle>
              <ItemDesc>{s.desc}</ItemDesc>
            </MenuItem>
          );
        })}
      </MenuSection>
      <FeedTitle>OUR MENU BOARD ALSO FEATURES ROTATING WEEKLY SPECIALS</FeedTitle>
      <FeedTitle>FEED ME OPTION $75PP</FeedTitle>
      {/* <FeedTitle>FEED ME OPTION $75PP</FeedTitle> */}
      <FeedDesc>
        Can’t decide? Let us pick for you. A shared menu of our favourites,
        happy to accommodate dietaries.
      </FeedDesc>
      <Socials />
      <Footer />
    </Container>
  );
};

export default Index;
