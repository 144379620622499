import React, { useState, useRef } from "react";
import styled from "styled-components";
// import { Link } from "react-router-dom";
import ProgressiveImage from "./progressiveImage";

import LongWeekend from "./LongWeekend.js";

import Lunch from "../images/Lunch.png";
import MumDay from "../images/mum_day.png";
import Lasagne from "../images/lasagne.jpg";
import PandV from "../images/PandV.png";
import PandVPromo from "../images/P&V_ByFabiano.png";
import Holidays from "../images/holidays.png";

const Container = styled.div`
  display: block;
  z-index: 11;
  position: relative;

  margin-left: auto;
  margin-right: auto;
`;

// background-color: #465941; -- deep green
// background-color: #b75605; -- orange

const Dialog = styled.dialog`
  width: 85%;

  background-color: #465941;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.5), 0 10px 20px 0 rgba(0, 0, 0, 0.5);
  @media only screen and (min-width: 480px) {
    width: 400px;
  }
  @media only screen and (min-width: 768px) {
    width: 500px;
  }
  @media only screen and (min-width: 1024px) {
    width: 600px;
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const ImageContainer = styled.div`
  display: flex;
  max-width: 1000px;
  @media only screen and (min-width: 480px) {
    width: 100%;
    height: auto;
  }
  @media only screen and (min-width: 768px) {
    width: 100%;
    height: auto;
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Button = styled.button`
  display: block;

  margin-left: auto;
  margin-right: auto;

  background-color: #222;
  border-radius: 4px;
  border-style: none;
  box-sizing: border-box;
  color: #f8be98;
  cursor: pointer;
  font-family: "FuturaPT", "monospace";
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  min-height: 44px;
  min-width: 10px;
  outline: none;
  padding: 9px 20px 8px;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
`;

const Link = styled.a`
  display: flex;
  justify-content: center;
  padding: 0 20px;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Modal = () => {
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Container>
      <Dialog open={open}>
        {/* <Button onClick={handleClose}>Close</Button> */}
        <ImageContainer>
          <Link
            href="https://bookings.nowbookit.com/?accountid=1e572e62-f311-4830-a8b0-d19dd0a287a6&theme=outlined-light&accent=hex,212121&venueid=4289&font=Inconsolata&date=2024-11-10&serviceids=event_QBO8BJI5HJRCO_1730258461346"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ProgressiveImage src={Holidays} alt="Holiday hours, see hours page for details" />
          </Link>
          {/* <ProgressiveImage src={Lasagne} alt="Sunday Lunch special" /> */}
        </ImageContainer>
        {/* <Link
          href="https://bookings.nowbookit.com/?accountid=1e572e62-f311-4830-a8b0-d19dd0a287a6&theme=outlined-light&accent=hex,212121&venueid=4289&font=Inconsolata&date=2024-11-10&serviceids=event_QBO8BJI5HJRCO_1730258461346"
          target="_blank"
          rel="noopener noreferrer"
        >
          Click here to book! <br/><br/>
        </Link> */}
        <Button onClick={handleClose}>Close</Button>
      </Dialog>
    </Container>
  );
};

export default Modal;
