import React, { useEffect } from "react";
import "./App.css";
import "./global.style";
import styled from "styled-components";
import { Routes, Route } from "react-router-dom";
import WebFont from "webfontloader";

import Index from "./pages/indexPage";
import Menu from "./pages/menuPage";
import Hours from "./pages/hoursPage";

const Page = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
  background: #465941;
  min-height: 100vh;
`;

function App() {
  useEffect(() => {
    WebFont.load({
      custom: {
        families: ["OrgandaMN", "Inconsolata"],
        urls: ["/index.css"],
      },
    });
  }, []);
  return (
    <Page>
      <Routes>
        <Route exact path="/" element={<Index />} />
        <Route exact path="/menu" element={<Menu />} />
        <Route exact path="/hours" element={<Hours />} />
      </Routes>
    </Page>
  );
}

export default App;
